import { elements } from '../../config';
import { bulkElements } from '../../configBatch';

const AE = 'One Time Accidental';
const STD = 'Standard'; // Includes "Standard Extended'
const SCP = "SocketCare Premium";
//const SC = 'Extended'; - intentionally skipped as it is treated the same as Standard

export const planNames = {
    AE: AE,
    STD: STD,
    SCP: SCP
};

//Populate Shipping options select
export const PopulateShippingOptions = async (replacementTypeName, serialNumbers) => {
    try {
        let serviceData = window.state.serviceOptionsModel.result.serviceData;
        let plan, hasDeposit = false;
        let deposits = { totalSum: 0 };
        let replacementTypeToLower = replacementTypeName.toLowerCase();

        if (replacementTypeToLower.includes("advanced") || replacementTypeToLower.includes("premium")) {
            hasDeposit = true;
        }
        if (serviceData == null || serviceData == "undefined") {
            await window.state.serviceOptionsModel.getSocketShippingOptions();
            serviceData = window.state.serviceOptionsModel.result.serviceData;
        }

        let processingSelectWrapper = $(elements.processingSelectWrapper);
        let processingSelect = processingSelectWrapper.find('select');
        let placeholder = window.batchResources.pickProcessingOptionLabel;
        processingSelect.empty().append($('<option value="" selected disabled hidden>' + placeholder + '</option>')).show();

        plan = serviceData.find(x => x.deposit === hasDeposit);

        window.state.selectedPlan = plan;

        if (plan?.deposit == true) {
            deposits = await window.state.serviceOptionsModel.getMultipleDevicesDeposit(plan.currency, serialNumbers);

            window.state.serviceOptionsModel.result.depositAmount = deposits.totalSum;

            $(bulkElements.formReplacementRequest).find(elements.depositCheckboxWrapper).show();
        }
        else {
            $(bulkElements.formReplacementRequest).find(elements.depositCheckboxWrapper).hide();
        }


        const planName = sessionStorage.getItem('PlanName'); // Returns 'Advanced Express','SocketCare','Standard' or 'SocketCarePremium'
        let shippingOptionsList = plan?.shippingList
            .filter(function (x) {
                return x.planNames.includes(planName);
            });

        if (shippingOptionsList && shippingOptionsList.length > 0) {
            if (replacementTypeToLower.includes("premium")) {
                //Hide shipping options select since there is only one shipping option for SocketCarePremium
                processingSelectWrapper.hide();

                //Populate the Shipping Option table directly
                let option = JSON.parse(createServiceDetail(plan, shippingOptionsList[0], deposits).value);
                window.state.selectedServiceOption = option;
                DisplayServiceDetail(option);
            }
            else {
                //Show shipping options select
                processingSelectWrapper.show();

                //Append shipping options to the select element
                shippingOptionsList
                    .forEach((item) => {
                        let option = createServiceDetail(plan, item, deposits);
                        processingSelect.append($('<option>', option));
                    });
            }
        }
    } catch (error) {
        throw error;
    }
};

export const HideAllServiceOptions = () => {
    $(elements.serviceDetailsWrapper).hide();
    $(bulkElements.formReplacementRequest).find(".js-service-wrapper").hide();
}

export const ShowServiceOption = async (planName, serialNumbers) => {
    let defaultServiceOptionWrapper, advancedServiceOptionWrapper;

    switch (planName) {
        case SCP:
            //Only Socket Care Premium Service should be available, which is free for all orders
            defaultServiceOptionWrapper = $(bulkElements.formReplacementRequest).find("#premium-service-wrapper");
            break;
        case AE:
            defaultServiceOptionWrapper = $(bulkElements.formReplacementRequest).find("#accidental-standard-service-wrapper");
            advancedServiceOptionWrapper = $(bulkElements.formReplacementRequest).find("#accidental-advanced-service-wrapper");

            advancedServiceOptionWrapper.show();
            break;
        default:
            defaultServiceOptionWrapper = $(bulkElements.formReplacementRequest).find("#standard-standard-service-wrapper");
            advancedServiceOptionWrapper = $(bulkElements.formReplacementRequest).find("#standard-advanced-service-wrapper");

            advancedServiceOptionWrapper.show();
            break;
    }

    let defaultServiceOptionInput = defaultServiceOptionWrapper.find("input[name='serviceOption']");
    let defaultServiceOptionInputValue = defaultServiceOptionInput.val();

    defaultServiceOptionInput.prop("checked", true);
    await PopulateShippingOptions(defaultServiceOptionInputValue, serialNumbers);
    defaultServiceOptionWrapper.show();
}

export const OnShippingOptionSelect = (processingSelect) => {
    let option = null;
    if (processingSelect.prop('selectedIndex') != 0) {
        option = JSON.parse(processingSelect.val());

        option.expeditedShipping = window.state.selectedPlan?.replacementType == "Standard" && (option.line2 == "Express Shipping" || option.line2 == "Priority Shipping");

        window.state.selectedServiceOption = option;
        DisplayServiceDetail(option);
    }
    else {
        $(elements.serviceDetailsWrapper).hide();
    }

    return option;
}

export const DisplayServiceDetail = (service) => {
    let currencySign = getCurrencySign(service.currency);

    $(elements.serviceDetailsWrapper).show();
    $('#service-name').html(`${service.name}`);
    $('#service-line1').html(service.line1);
    $('#service-line2').html(service.line2);

    if (service.cost > 0) {
        $('#service-price').html(`${currencySign}${service.cost} (${window.batchResources.nonRefundableLabel})`);
    } else {
        $('#service-price').html(`${currencySign}${service.cost}`);
    }

    $('#deposit-price').html(`${currencySign}${service.deposit} ${service.depositBreakdownLabel}`);

    let totalPrice = Number(service.cost) + Number(service.deposit);
    $('#total-price').html(`${currencySign}${totalPrice}`);

    sessionStorage.setItem("TotalPrice", totalPrice);
}

function getDepositBreakdown(currencySign, individualDeposits){
    if (!individualDeposits || !individualDeposits.length || individualDeposits.length === 1) {
        return '';
    }

    let map = {};

    individualDeposits.forEach((deposit) => {
        if (map[deposit]) {
            map[deposit]++;
        }
        else {
            map[deposit] = 1;
        }
    });

    let labels = [];

    Object.keys(map).forEach(function(key,index) {
        labels.push(`${map[key]} x ${currencySign}${key}`);
    });

    let depositBreakdown = labels.join(', ');

    if (depositBreakdown) {
        depositBreakdown = '(' + depositBreakdown + ')';
    }

    return depositBreakdown;
}

function createServiceDetail(plan, item, deposits) {
    let currencySign = getCurrencySign(plan.currency);
    let depositBreakdown = getDepositBreakdown(currencySign, deposits.individualDeposits);

    const service = {
        name: plan.replacementType,
        id: item.idx,
        cost: item.cost,
        currency: plan.currency,
        depositRequired: plan.deposit,
        deposit: deposits.totalSum,
        depositBreakdownLabel: depositBreakdown,
        line1: item.shippingdescriptionlines?.line1,
        line2: item.shippingdescriptionlines?.line2,
        line3: item.shippingdescriptionlines?.line3,
        createdTime: item.createdTime,
        status: item.status
    };

    let text = `${service.line1}, ${service.line2}`;
    if (service.deposit > 0) {
        text += `\n, ${window.batchResources.depositLabel}: ${currencySign}${service.deposit}`;
    }
    const jsonService = JSON.stringify(service);
    return { value: jsonService, text: text }
}

function getCurrencySign(currency) {
    let result = "$";

    switch (currency.toUpperCase()) {
        case "EUR":
            result = "€";
            break;
        case "GBP":
            result = "£";
            break;
        default:
            result = "$";
            break;
    }

    return result;
}
